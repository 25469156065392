<template>
    <div class="Service">
        <div class="inner">
            <div class="sub_visual sub1bg">
                <div class="in">
                    <p class="su_tit">고객센터</p>
                </div>
            </div>
        </div>
        <div class="sub_content">
            <div class="main">
                <input type="radio" id="tab-1" name="show" checked/>
                <input type="radio" id="tab-2" name="show" checked/>
                <input type="radio" id="tab-3" name="show" />
                <input type="radio" id="tab-4" name="show" />
                <div class="tab">
                    <label for="tab-1">공지사항</label>
                    <label for="tab-2">자료실</label>
                    <label for="tab-3">FAQ</label>
                    <label for="tab-4">관심고객등록</label>
                </div>
                <div class="content">
                    <!-- 공지사항 -->
                    <div class="content-dis">
                        <div class="titlebox">
                            <div class="title">
                                <h2>공지사항</h2>
                                <p class="line"><img src="@/assets/패스 22.png"></p>
                            </div>
                        </div>
                        <div class="boardList">
                            <table> 
                                <thead>
                                    <tr>
                                        <th class="no">번호</th>
                                        <th class="ti">제목</th>
                                        <th class="da">등록일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="no">10</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr>
                                        <td class="no">9</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr>
                                        <td class="no">7</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr>
                                        <td class="no">6</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr>
                                        <td class="no">5</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr>
                                        <td class="no">4</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr>
                                        <td class="no">3</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr>
                                        <td class="no">2</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr class="last">
                                        <td class="no">1</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- 자료실 -->
                    <div class="content-dis">
                        <div class="titlebox">
                            <div class="title">
                                <h2>자료실</h2>
                                <p class="line"><img src="@/assets/패스 22.png"></p>
                            </div>
                        </div>
                        <div class="boardList">
                            <table> 
                                <thead>
                                    <tr>
                                        <th class="no">번호</th>
                                        <th class="ti">제목</th>
                                        <th class="da">등록일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="no">10</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr>
                                        <td class="no">9</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr>
                                        <td class="no">7</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr>
                                        <td class="no">6</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr>
                                        <td class="no">5</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr>
                                        <td class="no">4</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr>
                                        <td class="no">3</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr>
                                        <td class="no">2</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                    <tr class="last">
                                        <td class="no">1</td>
                                        <td><a href="">동해물과백두산이마르고닳도록하느님이보우하사우리나라만세 무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세</a></td>
                                        <td class="no">2022-02-22</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- FAQ -->
                    <div class="content-dis">
                        <div class="titlebox">
                            <div class="title">
                                <h2>FAQ</h2>
                                <p class="line"><img src="@/assets/패스 22.png"></p>
                            </div>
                            <div class="F_list">
                                <div class="faq-content">
                                    <button class="question" id="que-1"><span class="faq_tit">Q</span><span>입주는 어떻게 하나요?</span></button>
                                    <div class="answer" id="ans-1">
                                        <td>
                                            <span class="faq_tit" style="color:#999;">A</span>
                                        </td>
                                        <td class="answers">
                                            자주 남겨주시는 문의사항에 대한 답변을 확인하실 수 있습니다. 
                                            이용 중 문제 상황이 발생하셨거나, 궁금하신 점이 있으실 경우, FAQ 페이지와 게임내 문의하기 기능을 적극 활용해 주세요!
                                            자주 남겨주시는 문의사항에 대한 답변을 확인하실 수 있습니다. 
                                            이용 중 문제 상황이 발생하셨거나, 궁금하신 점이 있으실 경우, FAQ 페이지와 게임내 문의하기 기능을 적극 활용해 주세요!
                                        </td>
                                    </div>
                                </div>
                                <div class="faq-content">
                                    <button class="question" id="que-2"><span id="que-2">Q</span><span>입주는 어떻게 하나요?</span></button>
                                    <div class="answer" id="ans-2">
                                        <td>
                                            <span class="faq_tit" style="color:#999;">A</span>
                                        </td>
                                        <td class="answers">
                                            자주 남겨주시는 문의사항에 대한 답변을 확인하실 수 있습니다. 
                                            이용 중 문제 상황이 발생하셨거나, 궁금하신 점이 있으실 경우, FAQ 페이지와 게임내 문의하기 기능을 적극 활용해 주세요!
                                            자주 남겨주시는 문의사항에 대한 답변을 확인하실 수 있습니다. 
                                            이용 중 문제 상황이 발생하셨거나, 궁금하신 점이 있으실 경우, FAQ 페이지와 게임내 문의하기 기능을 적극 활용해 주세요!
                                        </td>
                                    </div>
                                </div>
                                <div class="faq-content">
                                    <button class="question" id="que-3"><span id="que-3">Q</span><span>입주는 어떻게 하나요?</span></button>
                                    <div class="answer" id="ans-3">
                                        <td>
                                            <span class="faq_tit" style="color:#999;">A</span>
                                        </td>
                                        <td class="answers">
                                            자주 남겨주시는 문의사항에 대한 답변을 확인하실 수 있습니다. 
                                            이용 중 문제 상황이 발생하셨거나, 궁금하신 점이 있으실 경우, FAQ 페이지와 게임내 문의하기 기능을 적극 활용해 주세요!
                                            자주 남겨주시는 문의사항에 대한 답변을 확인하실 수 있습니다. 
                                            이용 중 문제 상황이 발생하셨거나, 궁금하신 점이 있으실 경우, FAQ 페이지와 게임내 문의하기 기능을 적극 활용해 주세요!
                                        </td>
                                    </div>
                                </div>
                                <div class="faq-content">
                                    <button class="question" id="que-4"><span id="que-4">Q</span><span>입주는 어떻게 하나요?</span></button>
                                    <div class="answer" id="ans-4">
                                        <td>
                                            <span class="faq_tit" style="color:#999;">A</span>
                                        </td>
                                        <td class="answers">
                                            자주 남겨주시는 문의사항에 대한 답변을 확인하실 수 있습니다. 
                                            이용 중 문제 상황이 발생하셨거나, 궁금하신 점이 있으실 경우, FAQ 페이지와 게임내 문의하기 기능을 적극 활용해 주세요!
                                            자주 남겨주시는 문의사항에 대한 답변을 확인하실 수 있습니다. 
                                            이용 중 문제 상황이 발생하셨거나, 궁금하신 점이 있으실 경우, FAQ 페이지와 게임내 문의하기 기능을 적극 활용해 주세요!
                                        </td>
                                    </div>
                                </div>
                                <div class="faq-content">
                                    <button class="question" id="que-5"><span id="que-5">Q</span><span>입주는 어떻게 하나요?</span></button>
                                    <div class="answer" id="ans-5">
                                        <td>
                                            <span class="faq_tit" style="color:#999;">A</span>
                                        </td>
                                        <td class="answers">
                                            자주 남겨주시는 문의사항에 대한 답변을 확인하실 수 있습니다. 
                                            이용 중 문제 상황이 발생하셨거나, 궁금하신 점이 있으실 경우, FAQ 페이지와 게임내 문의하기 기능을 적극 활용해 주세요!
                                            자주 남겨주시는 문의사항에 대한 답변을 확인하실 수 있습니다. 
                                            이용 중 문제 상황이 발생하셨거나, 궁금하신 점이 있으실 경우, FAQ 페이지와 게임내 문의하기 기능을 적극 활용해 주세요!
                                        </td>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 관심고객등록 -->
                    <div class="content-dis">
                        <div class="titlebox">
                            <div class="title">
                                <h2>관심고객등록</h2>
                                <p class="line"><img src="@/assets/패스 22.png"></p>
                            </div>
                        </div>
                        <div class="interest">
                            <table>
                                <tbody>
                                    <tr style="border-top: 2px solid #707070;">
                                        <td class="content_title">성명</td>
                                        <td>
                                            <input type="text" placeholder="이름" name="user_name" id="user_Name" v-model="userData.user_name" required />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="content_title">전화번호</td>
                                        <td>
                                            <input type="tel" size=30  id="user_Number" max="11"  placeholder="" v-model="userData.user_number" /> '-'을 제외한 휴대폰번호를 입력해 주세요
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="content_title">생년월일</td>
                                        <td> 
                                            <input type="text" class="non-kr" id="user_Birth" placeholder=""  aria-invalid="false" v-model="userData.user_birth" /> 예) 2019-12-31
                                        </td>
                                    </tr>
                                    <tr style="border-bottom: 2px solid #707070;">
                                        <td class="content_title">이메일</td>
                                        <td> 
                                            <input type="text" id="user_email"  placeholder="이메일" v-model="userData.user_email" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button class="deleteBtn" type="button" @click="favoriteBtn(userData)">등록하기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            userData: {
				user_name: '', // 이름
				user_birth: '', // 생년월일
				user_number: '', // 핸드폰 번호
                user_email: '', // 이메일
            },
            show: true
        }
    },
    mounted() {
        console.log(this.$route.params.type)
        
        if(this.type == '자료실') { 
            document.getElementById('tab-2').checked = true;
            // console.log(this.type)
            const id = document.getElementById('tab-2');
            console.log(id.checked)
        }

        const items = document.querySelectorAll('.question');

        function openCloseAnswer() {
            const answerId = this.id.replace('que', 'ans');

            if(document.getElementById(answerId).style.display === 'block') {
            document.getElementById(answerId).style.display = 'none';
            document.getElementById(this.id + '-toggle').textContent = '+';
            } else {
            document.getElementById(answerId).style.display = 'block';
            document.getElementById(this.id + '-toggle').textContent = '-';
            }
        }

        items.forEach(item => item.addEventListener('click', openCloseAnswer));
    },
    methods: {

        favoriteBtn(list) {
            // 유효성검사
			var name = /^[가-힣]{2,4}|[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/; //이름
			var birth = /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/ // 생년월일
			var phone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; //핸드폰 번호
            var email = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i // 이메일

            console.log(list)

			if(list.user_name.length == 0) {
				alert('이름을 입력해주세요')

				return false;

			} else if(!name.test(list.user_name)) {
				alert('이름을 올바로 입력해주세요')

				return false;
			} 
			if(list.user_birth.length == 0) {
				alert('생년월일 입력해주세요')

				return false;
			} else if(!birth.test(list.user_birth)){
				alert('생년월일 확인해주세요')
			}
			if(list.user_number.length == 0){
				alert('핸드폰 번호 입력해주세요')

				return false;

			} else if(!phone.test(list.user_number)){
				alert('핸드폰 번호를 확인해 주세요')

				return false;
			}
			if(list.user_email.length == 0){
				alert('이메일 입력해주세요')

				return false;

			} else if(!email.test(list.user_email)){
				alert('이메일 형식이 올바르지 않습니다')

				return false;
			}
            console.log("등록") 

			axios.post('https://192.168.0.63:8080/favorite', JSON.stringify(list), {headers: {"Content-Type": `application/json`}})
			.then(response => {
				console.log(response)
				if(response.data.statusCode == 200) {
					alert("관심등록 되었습니다")
				}else {	
					alert("다시 시도해주세요");
					console.log(response)
				}
			}).catch(err => {
				console.log(err);
			})
        }
    }
}
</script>
<style lang="scss">
.Service {position: relative; width: 100%;
    .inner{overflow: hidden;
        .sub_visual.sub1bg{background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../assets/img/menu_background.png") 0% 0% no-repeat padding-box; opacity: 0.9; width: 100%; height: 420px; background-size: cover;
            .in{width:100%; text-align: center; padding-top: 250px;
                .su_tit{text-align: center; font: normal normal bold 24px/29px GyeonggiTitleVOTF; letter-spacing: 0px; color: #FFFFFF; opacity: 1;}
            } 
        }
    }
    .sub_content{
        .main_title{position: relative; text-align: center;
            h2{margin-bottom: 60px; color: black; font-size: 38px; letter-spacing: -1px; font-weight: 500;} 
            h2:after{content: ''; display: block; margin: 15px auto 0; width: 35px; height: 3px; background: black;}
            
        }
        .main input[type=radio] { display: none; }
        #tab-1:checked ~ .tab label:nth-child(1),
        #tab-2:checked ~ .tab label:nth-child(2),
        #tab-3:checked ~ .tab label:nth-child(3),
        #tab-4:checked ~ .tab label:nth-child(4) {box-shadow: inset 0 -3px 0 #002a9b;}
        .content > div { display: none;}

        #tab-1:checked ~ .content div:nth-child(1),
        #tab-2:checked ~ .content div:nth-child(2),
        #tab-3:checked ~ .content div:nth-child(3),
        #tab-4:checked ~ .content div:nth-child(4) { display: block;}
        .main { margin: 0 auto; max-width: 1280px;}
        .tab { overflow: hidden; border-bottom: 1px solid #E0E0E0; opacity: 1; text-align: center;}
        .tab label {width: 206px; font: normal normal 500 20px/29px Noto Sans KR; letter-spacing: 0px; color: #626262; opacity: 1; cursor: pointer;text-align: center;text-align: center; padding: 15px 0; text-transform: uppercase; user-select: none; -webkit-user-select: none;}
        
        .content {min-height: 1000px;}
        .content > div{ line-height: 1.5; font-size: 17px;}

        .titlebox{
            .title{margin-top: 3%; margin-bottom: 3%;
                h2{text-align: left; font: normal normal 900 32px/47px Noto Sans KR; letter-spacing: 0px; color: #333333; opacity: 1;}
                .line{margin-top: -15px; }
            }
            .text{text-align: left; font: normal normal bold 16px/24px Noto Sans KR; letter-spacing: 0px; color: #002A9B; opacity: 1;}
        }
        .boardList{width: 100%; 
            table{width: 100%;}
            thead{width: 100%;
                th{text-align: center; border-top: 1px solid #D5D5D5; border-bottom: 1px solid #D5D5D5; background: #F5F5F5 0% 0% no-repeat padding-box; height: 50px;}
                .no{width: 10%;}
                .ti{width: 70%; margin-right: 10%;}
                .da{width: 15%;}
            }
            tbody{
                a{color: black; text-decoration: none;}
                td{text-align: center; height: 50px; font: normal normal 500 18px/27px Noto Sans KR; letter-spacing: 0px; color: #333333; } 
                .no {font: normal normal normal 14px/20px Noto Sans KR; color: #626262;}
                .last{border-bottom: 1px solid #D5D5D5;}
            }
        }
        // FAQ
        .F_list{border-top: 1px solid #D5D5D5; border-bottom: 1px solid #D5D5D5;
            .faq-content {
                .question {font: normal normal 500 20px/29px Noto Sans KR; letter-spacing: 1px; color: #333333; padding: 30px 0; cursor: pointer; border: none; outline: none;  background: none; width: 100%;  text-align: left; opacity: 1;}
                // .question:hover { color: #2962ff;}
                span{padding-left: 37px;}
                .faq_tit{font: normal normal 500 20px/29px Noto Sans KR; letter-spacing: 1px; color: #333333;}
                // [id$="-toggle"] {margin-right: 15px;}
                .answer{display: none; padding: 30px 0; background: #F5F5F5 0% 0% no-repeat padding-box; height: 153px; width: 1320px;
                    .answers{width: 1200px; padding-left: 40px; font: normal normal 500 20px/30px Noto Sans KR; text-align: left; letter-spacing: -1px; color: #002A9B; opacity: 1;}
                }
            }
        }
        // 관심고객등록
        .interest{
            table{width: 100%; margin-bottom: 10px; border-collapse: collapse; border-spacing: 0; 
                .content_title{text-align: center; background: #F5F5F5 0% 0% no-repeat padding-box; opacity: 1; width: 208px; font: normal normal 500 16px/24px Noto Sans KR; letter-spacing: 1.92px; color: #333333; opacity: 1;}
                tr{border: 1px solid #E0E0E0; }
                td{font-size: 15px; padding: 10px; overflow: hidden; word-break:normal; font-weight: 400;}
            }
            button{height: 50px; width: 200px; opacity: 1; display: block; font: normal normal bold 16px/24px Noto Sans KR; letter-spacing: 0px; color: #FFFFFF; margin: 0px auto; margin-top: 5%; background: #002A9B 0% 0% no-repeat padding-box; border-radius: 30px; text-align: center;}
        }
    }
}


</style>